import React from "react";
import { Route } from "react-router-dom";
import lead_gen from '../lead-gen-scraping.png';
import market_rs from '../market-research.png';
import competitor from '../competitor-analysis.png';
import scrapingsnap from '../scraping-snap.png'

const Scraping = () => {
    return (
      <div className="home-center">
        <h1 className="headers">ScrapeMagnet Scraping solutions</h1>
        <p className="about">
        Embark on a journey of data-driven insights with Web Scraping. Experience the efficiency of gathering extensive data swiftly and strategically. Web scraping becomes the compass guiding decision-makers through market dynamics, competitor landscapes, and customer sentiments. In the digital realm, it's the bridge connecting businesses to the wealth of information that fuels innovation and strategic decision-making.
</p>
<img src={scrapingsnap} className='use-case' />
<img src={market_rs} className='use-case' />
<br/>
<img src={lead_gen} className='use-case' />
<img src={competitor} className='use-case' />


      </div>
    );
  };
  
  export default Scraping;
  