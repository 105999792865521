import React from "react";
import { Link } from 'react-router-dom';
const Toolbar = () => {

    
    return (
      <div className="toolbar-container">
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      { <Link to="/blog">Blog</Link> }
      </div>
    );
  };
  
  export default Toolbar;
  