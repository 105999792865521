import React from "react";
import { Route } from "react-router-dom";
import analysis_customer from "../analysis-customer.png"
import analysis_market from "../analysis-market.png"
import analyticsdashboard from "../analytics-dashboard.png"
import analyticspredict from "../analytics-predict.png"

const Analytics = () => {
    return (
      <div className="home-center">
        <h1 className="headers">ScrapeMagnet Analytics solutions</h1>
        <p className="about">
        Embark on a journey of discovery with Analytics, where data unveils its transformative power. Uncover the art of turning raw information into actionable insights, propelling businesses towards informed decision-making. Analytics isn't just about numbers; it's the storyteller that reveals customer behaviors, market trends, and future trajectories.
        </p>
        <img src={analysis_customer} className='use-case'/>
        <img src={analysis_market} className='use-case'/>
        <br/>
        <img src={analyticsdashboard} className='use-case'/>
        <img src={analyticspredict} className='use-case'/>

      </div>
    );
  };
  
  export default Analytics;
  