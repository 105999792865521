import React from "react";
import { Route } from "react-router-dom";

const About = () => {
    return (
      <div className="home-center">
        <h1 className="headers">About us</h1>
        <br></br><br></br>
        <p className="about">
        Welcome to ScrapeMagnet, a dynamic startup passionate about revolutionizing
        industries through cutting-edge technologies. With a focus on automation,
        artificial intelligence, web scraping, and analytics, we are committed to
        delivering innovative solutions that drive efficiency and enhance business
        intelligence.
      </p>
      <p className="about">
        As a startup, our journey began with a vision to redefine how businesses
        harness the power of technology. Our team of experts brings a wealth of
        knowledge and experience to the table, enabling us to navigate the ever-evolving
        landscape of automation, AI, and data analytics.
      </p>
      <p className="about">
        At ScrapeMagnet, we believe in the transformative potential of technology.
        Whether it's automating repetitive tasks, leveraging AI for intelligent insights,
        scraping valuable data, or analyzing information to make informed decisions,
        we strive to empower businesses to thrive in the digital era.
      </p>

      </div>
    );
  };
  
  export default About;
  