import React from "react";
import { Route } from "react-router-dom";

const Blog = () => {
    return (
      <div className="home-center">
        <h1 className="headers">ScrapeMagnet Blog (COMING SOON)</h1>
        <div className="padder">

        </div>
      </div>
    );
  };
  
  export default Blog;
  