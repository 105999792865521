import React from 'react';
import scrapelogo from './scrapemagnet.png';
import './App.css';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import Homepage from './components/Home';
import About from './components/About';
import Blog from './components/Blog';
import Toolbar from './components/Toolbar';
import Scraping from './components/Scraping';
import AI from './components/AI';
import Automation from './components/Automation';
import ScrollToTop from './components/ScrollToTop';
import Analytics from './components/Analytics';
import CalendlyWidget from './components/Calendly';


function App() {
  return (
    <Router>
    <ScrollToTop />
      <div>
      
        <div className="toolbar">
          <div className='logo'>
            <img src={scrapelogo} alt="ScrapeMagnet Logo" />
            
          </div>
          <Toolbar />
        </div>
        <div className="page-content">
        <CalendlyWidget />  
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/ai" element={<AI />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/automation" element={<Automation />} />
            <Route path="/scraping" element={<Scraping />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
          </Routes>
          <CalendlyWidget />  
        </div>
      
      </div>
    </Router>

  );
}

export default App;
