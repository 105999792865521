import React from "react";
import { Route } from "react-router-dom";
import autosecurity from '../automation-security.png'
import autoinvoice from '../automation-invoice.png'

const Automation = () => {
    return (
      <div className="home-center">
        <h1 className="headers">ScrapeMagnet Automation Services</h1>
        <p className="about">
            
        Step into a realm of efficiency and precision through Automation. Eliminate manual errors and elevate productivity as Automation takes center stage. In manufacturing, witness accelerated production cycles and heightened safety standards. Automation not only revolutionizes processes but also liberates human potential, allowing talents to shine in areas demanding creativity and strategic thinking.
</p>
<img src={autosecurity} className='use-case' />
<img src={autoinvoice} className='use-case' />


      </div>
    );
  };
  
  export default Automation;
  