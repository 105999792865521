import React from "react";
import { Route } from "react-router-dom";
import lead_gen from '../lead-gen-scraping.png';
import market_rs from '../market-research.png';
const AI = () => {
    return (
      <div className="home-center">
        <h1 className="headers">ScrapeMagnet AI solutions</h1>
        <p className="about">
        Unlock the power of Artificial Intelligence (AI) to revolutionize decision-making. Harnessing AI empowers industries with lightning-fast data analysis, turning complex information into actionable insights. By automating repetitive tasks, AI liberates valuable time for creativity and innovation. From personalized healthcare solutions to dynamic marketing strategies, AI is the driving force behind smarter, more efficient processes.
</p>
<img src={lead_gen} className='use-case'/>
<img src={market_rs} className='use-case'/>

<p className="about">
Discover the boundless applications of AI across diverse sectors. In healthcare, AI pioneers breakthroughs in diagnostics, drug discovery, and patient-centered care. Finance embraces AI algorithms for precision in market analysis, investment management, and fraud prevention. Manufacturing reaps the rewards of predictive maintenance, optimizing production lines. AI-driven virtual assistants elevate customer support, ensuring seamless interactions. The future of transportation unfolds with autonomous vehicles, a testament to AI's transformative capabilities.
</p>
<p className="about">
AI not only propels innovation but also holds the key to cost savings and revenue growth. Automated processes reduce operational costs, while predictive analytics fine-tunes supply chains for efficiency. Personalized marketing, powered by AI, enhances customer engagement, translating into increased revenue streams. In the dynamic business landscape, AI emerges as a strategic ally, guiding organizations towards unparalleled success.
        </p>
        
      </div>
    );
  };
  
  export default AI;
  