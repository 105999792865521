import React from 'react';
import scrapingpic from '../scrapingpic.jpg';
import automationpic from '../automationpic.png';
import analytics from '../analytics.jpg';
import aipic from '../aipic.jpg';
import { Link } from 'react-router-dom';


const Homepage = () => {
  return (
    <div className='home-center'>
      <h1 className='headers'>Welcome to ScrapeMagnet</h1>

      <p>Serving you with your AI and Data needs.</p>
      <div className="service-boxes">
        <div className="service-box">
        <Link to='/scraping' className='link-style'>
          <h2>Scraping</h2>
          <div className='homepage-box-pic'>
          <img src={scrapingpic } />
          </div>
          <p>Explore our scraping services.</p>
          </Link>
      </div>
    
        <div className="service-box">
<Link to='/analytics' className='link-style'>
          <h2>Data Analytics</h2>
          <div className='homepage-box-pic'>
          <img src={analytics } />
          </div>
          <p>Discover our data analytics solutions.</p>
          </Link>
        </div>
        <div className="service-box">
        <Link to='/automation' className='link-style'>
          <h2>Automation</h2>
          <div className='homepage-box-pic'>
          <img src={automationpic } />
          </div>
          <p>Experience our automation services.</p>
          </Link>
        </div>
        <div className="service-box">
        <Link to='/ai' className='link-style'>
          <h2>AI Services</h2>
          <div className='homepage-box-pic'>
          <img src={aipic } />
          </div>
          <p>Unlock the potential of AI with our services.</p>
          </Link>
        </div>
      </div>

    </div>
  );
};

export default Homepage;
